<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 81.09 50.42"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="grouper">
        <path class="cls-1"
              d="M14.34,10.85c4.32.43,9.45.89,9.45.89s-2.46-4.53-4.58-8.31A6.77,6.77,0,0,0,13.6,0,4.79,4.79,0,0,0,9.48,2.35C6.78,6.46,10.59,10.47,14.34,10.85Z"
        /><path class="cls-1"
                d="M9.38,21.15c3.5-2.56,7.61-5.66,7.61-5.66s-4.85-1.71-9-3.11A6.6,6.6,0,0,0,5.9,12C2.74,12-.53,14.14.07,18.09a5,5,0,0,0,5.25,4.36A6.94,6.94,0,0,0,9.38,21.15Z"
        /><path class="cls-1"
                d="M71.61,2.35A4.8,4.8,0,0,0,67.49,0a6.77,6.77,0,0,0-5.61,3.43c-2.12,3.78-4.58,8.31-4.58,8.31s5.13-.46,9.45-.89C70.5,10.47,74.31,6.46,71.61,2.35Z"
        /><path class="cls-1"
                d="M75.19,12a6.6,6.6,0,0,0-2.13.34c-4.11,1.4-9,3.11-9,3.11s4.11,3.1,7.61,5.66a6.92,6.92,0,0,0,4.06,1.3A5,5,0,0,0,81,18.09C81.62,14.14,78.35,12,75.19,12Z"
        /><polygon class="cls-1"
                   points="44.07 36.05 48.17 36.05 48.17 21.31 55.86 15.32 51.65 9.93 40.77 18.4 40.32 18.4 29.43 9.93 25.23 15.32 32.91 21.31 32.91 36.05 37.02 36.05 37.02 44.91 3.9 44.91 3.9 50.42 77.19 50.42 77.19 44.91 44.07 44.91 44.07 36.05"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #292828;
}
</style>
